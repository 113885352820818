import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ADMManageGenres from '../pages/Admin/General/Genres';

import ADMListPlaylistScriptTemplates from '../pages/Admin/Templates/PlaylistScriptTemplates/ListPlaylistScriptTemplates';
import ADMCreatePlaylistScriptTemplate from '../pages/Admin/Templates/PlaylistScriptTemplates/CreatePlaylistScriptTemplates';
import ADMUpdatePlaylistScriptTemplate from '../pages/Admin/Templates/PlaylistScriptTemplates/UpdatePlaylistScriptTemplates';

import ADMManageScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/ManageScriptTemplates';
import ADMCreateScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/CreateScriptTemplates';
import ADMEditScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/EditScriptTemplates';
import ADMTemplateVignettes from '../pages/Admin/Templates/TemplateVignettes';

import ADMManageBlockTemplates from '../pages/Admin/Templates/BlockTemplates/ManageBlockTemplates';
import ADMCreateBlockTemplates from '../pages/Admin/Templates/BlockTemplates/CreateBlockTemplates';
import ADMEditBlockTemplates from '../pages/Admin/Templates/BlockTemplates/EditBlockTemplates';

import ADMManageCategories from '../pages/Admin/General/Categories';
import ADMManageCasters from '../pages/Admin/Casters/ManageCasters';
import ADMCasterTkVoices from '../pages/Admin/Casters/CasterTkVoices';
import ADMManageGenerationSchedules from '../pages/Admin/Contents/ProgramGenerationSchedules';
import ADMManageElements from '../pages/Admin/Contents/ManageElements';
import ADMManageTkVoices from '../pages/Admin/Contents/ManageTkVoices';
import ADMManageSignatures from '../pages/Admin/Contents/ManageSignatures';
import ADMManageOffs from '../pages/Admin/Contents/ManageOffs';
import ADMEditScript from '../pages/Admin/Contents/EditScript';
import ADMCreateScript from '../pages/Admin/Contents/CreateScript';
import ADMWeeklyScripts from '../pages/Admin/Contents/WeeklyScripts';
import ADMTkPlayConfig from '../pages/Admin/General/TkPlayConfig';
import ADMTkVoiceConfig from '../pages/Admin/General/TkVoiceConfig';
import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import { ElementsManagerNavigation } from '../components/Navigation';

const SignaturesManagerStack = () => {
	const routesMap = [
		{ path: '/admin/general/categories', page: ADMManageCategories },
		{ path: '/admin/general/genres', page: ADMManageGenres },
		{ path: '/admin/general/tkplay-config', page: ADMTkPlayConfig },
		{ path: '/admin/general/ai-voices-config', page: ADMTkVoiceConfig },

		{ path: '/admin/templates/script-templates', page: ADMManageScriptTemplates },
		{ path: '/admin/templates/script-templates/create', page: ADMCreateScriptTemplates },
		{ path: '/admin/templates/script-templates/:templateId/update', page: ADMEditScriptTemplates },
		
		{ path: '/admin/templates/block-templates', page: ADMManageBlockTemplates },
		{ path: '/admin/templates/block-templates/create', page: ADMCreateBlockTemplates },
		{ path: '/admin/templates/block-templates/:templateId/update', page: ADMEditBlockTemplates },
		{
			path: '/admin/templates/vignettes',
			page: ADMTemplateVignettes,
		},
		{ path: '/admin/templates/playlist-script-templates', page: ADMListPlaylistScriptTemplates },
		{
			path: '/admin/templates/playlist-script-templates/create',
			page: ADMCreatePlaylistScriptTemplate,
		},
		{
			path: '/admin/templates/playlist-script-templates/:templateId/update',
			page: ADMUpdatePlaylistScriptTemplate,
		},
		{ path: '/admin/contents/generation-schedules', page: ADMManageGenerationSchedules },
		{ path: '/admin/contents/scripts/create-script', page: ADMCreateScript },
		{ path: '/admin/contents/scripts/edit-script', page: ADMEditScript },
		{ path: '/admin/contents/elements', page: ADMManageElements },
		{ path: '/admin/contents/tk-voices', page: ADMManageTkVoices },
		{ path: '/admin/contents/signatures', page: ADMManageSignatures },
		{ path: '/admin/contents/offs', page: ADMManageOffs },
		{ path: '/admin/contents/playlists/weekly-scripts', page: ADMWeeklyScripts },
		{ path: '/admin/casters', page: ADMManageCasters },
		{ path: '/admin/casters/:casterId/tk-voices', page: ADMCasterTkVoices },
	];

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={['/', '/admin', '/login', '/loginAdmin']}>
					<Redirect to='/admin/contents/elements' />
				</Route>

				{routesMap.map(({ path, page: Page }) => (
					<Route exact path={path} key={path}>
						<AppLayout isAdmin={true} navigation={<ElementsManagerNavigation />}>
							<Page />
						</AppLayout>
					</Route>
				))}

				<Route exact path='*'>
					<NotFound />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default SignaturesManagerStack;
