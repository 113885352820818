import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import TutorialsRoutes from './Tutorials.routes';

import ADMGenerationRequests from '../pages/Admin/GenerationRequests';
import ADMAnalysisDashboard from '../pages/Admin/Analysis/Dashboard';
import ADMMixageConfig from '../pages/Admin/General/MixageConfig';
import ADMManageGenres from '../pages/Admin/General/Genres';

import ADMListPlaylistScriptTemplates from '../pages/Admin/Templates/PlaylistScriptTemplates/ListPlaylistScriptTemplates';
import ADMCreatePlaylistScriptTemplate from '../pages/Admin/Templates/PlaylistScriptTemplates/CreatePlaylistScriptTemplates';
import ADMUpdatePlaylistScriptTemplate from '../pages/Admin/Templates/PlaylistScriptTemplates/UpdatePlaylistScriptTemplates';

import ADMManageScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/ManageScriptTemplates';
import ADMCreateScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/CreateScriptTemplates';
import ADMEditScriptTemplates from '../pages/Admin/Templates/ScriptTemplates/EditScriptTemplates';

import ADMManageBlockTemplates from '../pages/Admin/Templates/BlockTemplates/ManageBlockTemplates';
import ADMCreateBlockTemplates from '../pages/Admin/Templates/BlockTemplates/CreateBlockTemplates';
import ADMEditBlockTemplates from '../pages/Admin/Templates/BlockTemplates/EditBlockTemplates';
import ADMTemplateVignettes from '../pages/Admin/Templates/TemplateVignettes';

import ADMManageProgramDemos from '../pages/Admin/Demos/ProgramDemos';
import ADMManageVoiceDemos from '../pages/Admin/Demos/VoiceDemos';
import ADMManageCasterDemos from '../pages/Admin/Demos/CasterDemos';
import ADMManageGenericDemos from '../pages/Admin/Demos/NewsDemos';
import ADMManageCategories from '../pages/Admin/General/Categories';
import ADMActiveUsers from '../pages/Admin/Users/ActiveUsers';
import ADMInactiveUsers from '../pages/Admin/Users/InactiveUsers';
import ADMUserDetails from '../pages/Admin/Users/UserDetails';
import ADMEditUser from '../pages/Admin/Users/EditUser';
import ADMCreateUser from '../pages/Admin/Users/CreateUser';
import ADMManageCasters from '../pages/Admin/Casters/ManageCasters';
import ADMCasterTkVoices from '../pages/Admin/Casters/CasterTkVoices';
import ADMManageGenerationSchedules from '../pages/Admin/Contents/ProgramGenerationSchedules';
import ADMManagePrograms from '../pages/Admin/Contents/ManagePrograms';
import ADMCreateProgram from '../pages/Admin/Contents/CreateProgram';
import ADMDeleteRequests from '../pages/Admin/Contents/DeleteRequests';
import ADMManageElements from '../pages/Admin/Contents/ManageElements';
import ADMManageTkVoices from '../pages/Admin/Contents/ManageTkVoices';
import ADMManageSignatures from '../pages/Admin/Contents/ManageSignatures';
import ADMManageOffs from '../pages/Admin/Contents/ManageOffs';
import ADMEditScript from '../pages/Admin/Contents/EditScript';
import ADMCreateScript from '../pages/Admin/Contents/CreateScript';
import ADMWeeklyScripts from '../pages/Admin/Contents/WeeklyScripts';
import ADMContracts from '../pages/Admin/Contracts/ManageContracts';
import ADMCreateContract from '../pages/Admin/Contracts/CreateContract';
import ADMBillets from '../pages/Admin/Contracts/Billets';
import ADMActivePlans from '../pages/Admin/Plans/ActivePlans';
import ADMInactivePlans from '../pages/Admin/Plans/InactivePlans';
import ADMUseTerms from '../pages/Admin/Terms';
import ADMContactRequests from '../pages/Admin/ContactRequests';
import ADMBanners from '../pages/Admin/General/Banners';
import ADMListNetworks from '../pages/Admin/Users/Networks/ListNetworks';
import ADMEditNetworks from '../pages/Admin/Users/Networks/EditNetwork';

import CMMRadioVignettesConfigs from '../pages/Commom/RadioVignettes/RadioVignettesConfigs';
import CMMListRadioVignettes from '../pages/Commom/RadioVignettes/ListRadioVignettes';

import CMMSettings from '../pages/Commom/Settings';
import CMMProgramDetails from '../pages/Commom/Programs/ProgramDetails';
import CMMManagePrograms from '../pages/Commom/Programs/ManagePrograms';
import CMMCreateProgram from '../pages/Commom/Programs/CreateProgram';
import CMMCreateVignettes from '../pages/Commom/CreateVignettes';
import CMMNetwork from '../pages/Commom/Network';
import CMMEditScript from '../pages/Commom/Scripts/EditScript';
import CMMCreateScript from '../pages/Commom/Scripts/CreateScript';
import CMMSignatures from '../pages/Commom/Signatures';
import CMMSoundtracks from '../pages/Commom/Sountracks';
import CMMTracks from '../pages/Commom/Tracks';
import CMMCategories from '../pages/Commom/Categories';
import CMMWeeklyScripts from '../pages/Commom/Playlists/WeeklyScripts';
import CMMManagePlaylists from '../pages/Commom/Playlists/ManagePlaylists';
import CMMOffs from '../pages/Commom/Voices/Offs';
import CMMNews from '../pages/Commom/Voices/News';
import CMMBlitz from '../pages/Commom/Voices/Blitz';
import CMMWpps from '../pages/Commom/Voices/Wpps';
import CMMInterviews from '../pages/Commom/Voices/Interviews';
import CMMCasters from '../pages/Commom/Casters';
import CMMContracts from '../pages/Commom/Contracts';
import CMMManageEpisodes from '../pages/Commom/Episodes/Manage';
import CMMCreateEpisode from '../pages/Commom/Episodes/CreateEpisode';
import CMMCreatedEpisode from '../pages/Commom/Episodes/CreatedEpisode';

import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import TutorialsLayout from '../components/TutorialsLayout';

import { TalkNavigation, AdminNavigation } from '../components/Navigation';
import ADMTkPlayConfig from '../pages/Admin/General/TkPlayConfig';
import ADMTkVoiceConfig from '../pages/Admin/General/TkVoiceConfig';

const AdminStack = () => {
	const routesMap = [
		{ path: '/admin/analysis/dashboard', page: ADMAnalysisDashboard, navigation: AdminNavigation },
		{
			path: '/admin/contents/programs/generation-requests',
			page: ADMGenerationRequests,
			navigation: AdminNavigation,
		},
		{ path: '/admin/general/banners', page: ADMBanners, navigation: AdminNavigation },
		{ path: '/admin/general/tkplay-config', page: ADMTkPlayConfig, navigation: AdminNavigation },
		{
			path: '/admin/general/ai-voices-config',
			page: ADMTkVoiceConfig,
			navigation: AdminNavigation,
		},
		{ path: '/admin/general/mixage-config', page: ADMMixageConfig, navigation: AdminNavigation },
		{ path: '/admin/general/categories', page: ADMManageCategories, navigation: AdminNavigation },
		{ path: '/admin/general/genres', page: ADMManageGenres, navigation: AdminNavigation },
		{
			path: '/admin/templates/playlist-script-templates',
			page: ADMListPlaylistScriptTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/playlist-script-templates/create',
			page: ADMCreatePlaylistScriptTemplate,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/playlist-script-templates/:templateId/update',
			page: ADMUpdatePlaylistScriptTemplate,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/script-templates',
			page: ADMManageScriptTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/script-templates/create',
			page: ADMCreateScriptTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/script-templates/:templateId/update',
			page: ADMEditScriptTemplates,
			navigation: AdminNavigation,
		},
		
		{
			path: '/admin/templates/block-templates',
			page: ADMManageBlockTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/block-templates/create',
			page: ADMCreateBlockTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/block-templates/:templateId/update',
			page: ADMEditBlockTemplates,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/templates/vignettes',
			page: ADMTemplateVignettes,
			navigation: AdminNavigation,
		},

		{ path: '/admin/demos/programs', page: ADMManageProgramDemos, navigation: AdminNavigation },
		{ path: '/admin/demos/ia-voices', page: ADMManageVoiceDemos, navigation: AdminNavigation },
		{
			path: '/admin/demos/news',
			page: ADMManageGenericDemos,
			navigation: AdminNavigation,
			props: { type: 'NEWS' },
		},
		{
			path: '/admin/demos/cash-tracks',
			page: ADMManageGenericDemos,
			navigation: AdminNavigation,
			props: { type: 'CASH-TRACK' },
		},
		{
			path: '/admin/demos/ads',
			page: ADMManageGenericDemos,
			navigation: AdminNavigation,
			props: { type: 'ADS' },
		},
		{ path: '/admin/demos/casters', page: ADMManageCasterDemos, navigation: AdminNavigation },
		{ path: '/admin/users/active-users', page: ADMActiveUsers, navigation: AdminNavigation },
		{ path: '/admin/users/active-users', page: ADMActiveUsers, navigation: AdminNavigation },
		{ path: '/admin/users/inactive-users', page: ADMInactiveUsers, navigation: AdminNavigation },
		{ path: '/admin/users/create-user', page: ADMCreateUser, navigation: AdminNavigation },
		{ path: '/admin/users/:userId/edit', page: ADMEditUser, navigation: AdminNavigation },
		{ path: '/admin/users/:userId/details', page: ADMUserDetails, navigation: AdminNavigation },
		{ path: '/admin/users/networks', page: ADMListNetworks, navigation: AdminNavigation },
		{
			path: '/admin/users/networks/:networkId',
			page: ADMEditNetworks,
			navigation: AdminNavigation,
		},
		{ path: '/admin/contents/programs', page: ADMManagePrograms, navigation: AdminNavigation },
		{
			path: '/admin/contents/generation-schedules',
			page: ADMManageGenerationSchedules,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/contents/programs/create-program',
			page: ADMCreateProgram,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/contents/programs/delete-requests',
			page: ADMDeleteRequests,
			navigation: AdminNavigation,
		},
		{ path: '/admin/contents/elements', page: ADMManageElements, navigation: AdminNavigation },
		{ path: '/admin/contents/tk-voices', page: ADMManageTkVoices, navigation: AdminNavigation },
		{ path: '/admin/contents/signatures', page: ADMManageSignatures, navigation: AdminNavigation },
		{ path: '/admin/contents/offs', page: ADMManageOffs, navigation: AdminNavigation },
		{
			path: '/admin/contents/scripts/create-script',
			page: ADMCreateScript,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/contents/scripts/edit-script',
			page: ADMEditScript,
			navigation: AdminNavigation,
		},
		{
			path: '/admin/contents/playlists/weekly-scripts',
			page: ADMWeeklyScripts,
			navigation: AdminNavigation,
		},
		{ path: '/admin/casters', page: ADMManageCasters, navigation: AdminNavigation },
		{
			path: '/admin/casters/:casterId/tk-voices',
			page: ADMCasterTkVoices,
			navigation: AdminNavigation,
		},
		{ path: '/admin/contracts', page: ADMContracts, navigation: AdminNavigation },
		{
			path: '/admin/contracts/create-contract',
			page: ADMCreateContract,
			navigation: AdminNavigation,
		},
		{ path: '/admin/contracts/billets', page: ADMBillets, navigation: AdminNavigation },
		{ path: '/admin/plans/active-plans', page: ADMActivePlans, navigation: AdminNavigation },
		{ path: '/admin/plans/inactive-plans', page: ADMInactivePlans, navigation: AdminNavigation },
		{ path: '/admin/terms', page: ADMUseTerms, navigation: AdminNavigation },
		{ path: '/admin/contact-requests', page: ADMContactRequests, navigation: AdminNavigation },
		{
			path: '/commom/radio-vignettes/configs',
			page: CMMRadioVignettesConfigs,
			navigation: TalkNavigation,
		},
		{ path: '/commom/radio-vignettes', page: CMMListRadioVignettes, navigation: TalkNavigation },
		{ path: '/commom/settings', page: CMMSettings, navigation: TalkNavigation },
		{ path: '/commom/programs', page: CMMManagePrograms, navigation: TalkNavigation },
		{ path: '/commom/programs/create', page: CMMCreateProgram, navigation: TalkNavigation },
		{
			path: '/commom/programs/:programId/details',
			page: CMMProgramDetails,
			navigation: TalkNavigation,
		},
		{ path: '/commom/elements/create', page: CMMCreateVignettes, navigation: TalkNavigation },
		{ path: '/commom/network', page: CMMNetwork, navigation: TalkNavigation },
		{ path: '/commom/soundtracks', page: CMMSoundtracks, navigation: TalkNavigation },
		{ path: '/commom/scripts/:scriptId/edit', page: CMMEditScript, navigation: TalkNavigation },
		{ path: '/commom/scripts/create', page: CMMCreateScript, navigation: TalkNavigation },
		{ path: '/commom/signatures', page: CMMSignatures, navigation: TalkNavigation },
		{ path: '/commom/voices/offs', page: CMMOffs, navigation: TalkNavigation },
		{ path: '/commom/voices/news', page: CMMNews, navigation: TalkNavigation },
		{ path: '/commom/voices/blitz', page: CMMBlitz, navigation: TalkNavigation },
		{ path: '/commom/voices/wpps', page: CMMWpps, navigation: TalkNavigation },
		{ path: '/commom/voices/interviews', page: CMMInterviews, navigation: TalkNavigation },
		{ path: '/commom/casters', page: CMMCasters, navigation: TalkNavigation },
		{ path: '/commom/playlists', page: CMMManagePlaylists, navigation: TalkNavigation },
		{
			path: '/commom/playlists/weekly-scripts',
			page: CMMWeeklyScripts,
			navigation: TalkNavigation,
		},
		{ path: '/commom/tracks', page: CMMTracks, navigation: TalkNavigation },
		{ path: '/commom/tracks/categories', page: CMMCategories, navigation: TalkNavigation },
		{ path: '/commom/contracts', page: CMMContracts, navigation: TalkNavigation },
		{ path: '/commom/episodes', page: CMMManageEpisodes, navigation: TalkNavigation },
		{ path: '/commom/episodes/create-episode', page: CMMCreateEpisode, navigation: TalkNavigation },
		{
			path: '/commom/episodes/:episodeId/details',
			page: CMMCreatedEpisode,
			navigation: TalkNavigation,
		},
	];

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/commom'>
					<Redirect to='/commom/episodes' />
				</Route>
				<Route exact path={['/', '/admin', '/login', '/loginAdmin']}>
					<Redirect to='/admin/contents/programs/generation-requests' />
				</Route>

				{routesMap.map(({ path, page: Page, navigation: Navigation, props }) => (
					<Route exact path={path} key={path}>
						<AppLayout isAdmin={path.startsWith('/admin')} navigation={<Navigation />}>
							<Page {...(props || {})} />
						</AppLayout>
					</Route>
				))}

				<TutorialsLayout>
					<TutorialsRoutes />
				</TutorialsLayout>

				<Route exact path='*'>
					<NotFound />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default AdminStack;
