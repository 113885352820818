import React, { useState, useEffect, useCallback } from 'react';
import {
	Button,
	Card,
	PageHeader,
	Typography,
	Divider,
	Select,
	Form,
	Spin,
	message,
	Tooltip,
	Modal,
	Input,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Script from '../../../../components/Script';
import ConfigScriptStrs from '../../../../components/ConfigScriptStrs';
import ElementsTabs from '../../../../components/ElementsTabs';
import { Container, Grid, ScriptWrapper, ButtonContainer, OptionsContainer } from './styles';

import UsersAPI from '../../../../services/sdks/user';
import ScriptsAPI from '../../../../services/sdks/scripts';
import ProgramsAPI from '../../../../services/sdks/programs';
import ElementsAPI from '../../../../services/sdks/elements';

import { FiMaximize, FiMinimize, FiSave, FiSettings, FiXSquare } from 'react-icons/fi';

import { useScript } from '../../../../hooks';

import env from '../../../../config/env';
import TKVoiceConfigAPI from '../../../../services/sdks/tkVoiceConfig';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Conteúdos' },
		{ breadcrumbName: 'Criar modelo' },
	],
	style: { marginBottom: 12 },
};
const EditScript = () => {
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(undefined);
	const [programs, setPrograms] = useState([]);
	const [name, setName] = useState('');
	const [errors, setErrors] = useState({});
	const [fallback, setFallback] = useState({ initialData: true });
	const [dataLoaded, setDataLoaded] = useState(false);
	const [showConfigStrsModal, setShowConfigStrsModal] = useState(false);
	const [tkVoiceTypes, setTkVoiceTypes] = useState({ caster: [], local: [] });
	const [weekDay, setWeekDay] = useState(undefined);

	const {
		cleanUp,
		setElements,
		script,
		program,
		setProgram,
		isSorting,
		setIsSorting,
		onElementMove,
		compact,
		setCompact,
		setScript,
	} = useScript();

	const handleCreateScript = useCallback(async () => {
		const validationErrors = { ...errors, name: !name, weekDay: weekDay === undefined };

		setErrors(validationErrors);

		if (Object.values(validationErrors).some((e) => e)) {
			return message.error('Informe todos os campos obrigatórios');
		}

		try {
			setFallback((prev) => ({ ...prev, updatingScript: true }));

			const payload = { body: script.body, program: program?._id, userId: user._id, name, weekDay };
			await ScriptsAPI.store({ payload });

			message.success('Modelo criado com sucesso', 2, () => window.location.reload());
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
			message.error(error?.response?.data?.message);

			setFallback((prev) => ({ ...prev, updatingScript: false }));
		}
	}, [name, script, program, user, weekDay, errors]);

	useEffect(() => cleanUp(), [cleanUp]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setUsers(users);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchTkVoiceTypes = async () => {
			try {
				const res = await TKVoiceConfigAPI.getPopulatedTypes(program._id, user._id);
				setTkVoiceTypes(res.data.types);
			} catch (error) {
				setTkVoiceTypes({ caster: [], local: [] });
			}
		};

		if (program && user) {
			fetchTkVoiceTypes();
		}
	}, [program, user]);

	useEffect(() => {
		const fetchUserPrograms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(programs);
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (user) {
			fetchUserPrograms();
		}
	}, [user]);

	useEffect(() => {
		const fetchProgramElements = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingProgramElements: true }));

				const {
					data: { elements },
				} = await ElementsAPI.index({ query: `userId=${user._id}&program=${program?._id}` });

				const cashTracks = elements.filter(({ type }) => type === 'CASHTRACK');
				const customs = elements.filter(({ type }) => type === 'CUSTOM');
				const merchans = elements.filter(({ type }) => ['SPONSOR', 'OFFERING'].includes(type));
				const trackAds = elements.filter(({ type }) => type === 'TRACK-AD');

				setElements({ cashTracks, customs, merchans, trackAds });
				setFallback((prev) => ({ ...prev, fetchingProgramElements: false }));
				setDataLoaded(true);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (user && program) {
			fetchProgramElements();
		}
	}, [user, program, setElements]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Criar modelo' />

			<PageHeader title='Criar Modelo' breadcrumb={breadcrumb}>
				{!dataLoaded && (
					<>
						<Divider />

						<Form {...layout}>
							<Form.Item required label='Selecione o usuário'>
								<Select
									showSearch
									optionFilterProp='children'
									placeholder='Selecione o usuário'
									value={user ? JSON.stringify(user) : undefined}
									onChange={(value) => setUser(JSON.parse(value))}
									filterOption={(input, { props: { _search } }) => {
										const regex = new RegExp(input, 'i');

										return _search.match(regex);
									}}>
									{users.map((user) => {
										const { radioName, city, state, email } = user;

										return (
											<Select.Option
												_search={`${radioName}${city}${state}${email}`}
												key={user._id}
												value={JSON.stringify(user)}>
												{radioName} - {city}/{state} ({email})
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>

							<Form.Item required label='Selecione o programa'>
								<Spin spinning={fallback?.fetchingPrograms === true} tip='Buscando programas...'>
									<Select
										showSearch
										disabled={!user}
										optionFilterProp='children'
										placeholder='Selecione o programa'
										value={program ? program?._id : undefined}
										onChange={(value) => {
											const program = programs.find((p) => p?._id === value);

											setProgram({
												...program,
												isTalkProgram: program?.userId?._id === env.talkId,
											});
										}}
										filterOption={(input, { props: { children } }) => {
											const regex = new RegExp(input, 'i');

											return children.match(regex);
										}}>
										{programs.map((program) => (
											<Select.Option key={program._id} value={program._id}>
												{program.name}
											</Select.Option>
										))}
									</Select>
								</Spin>
							</Form.Item>
						</Form>
					</>
				)}
			</PageHeader>

			<Container isSorting={isSorting}>
				{dataLoaded && (
					<>
						<Card>
							<Grid>
								<ElementsTabs
									isAdmin={true}
									programId={program?._id}
									userId={user._id}
									allowGlobalNews={user?.allowGlobalNews}
									allowGlobalWpp={user?.allowGlobalWpp}
									tkVoiceTypes={tkVoiceTypes}
									allowGlobalInterview={user?.allowGlobalInterview}
								/>

								<ScriptWrapper>
									<Typography.Title level={4}>Modelo</Typography.Title>

									<Divider style={{ margin: '8px 0' }} />

									<OptionsContainer>
										<Button
											style={{ flex: 1 }}
											size='small'
											disabled={script?.body?.length === 0 || script?.isShared}
											onClick={() => setShowConfigStrsModal(true)}>
											<FiSettings /> Configurar trilhas sonoras
										</Button>

										<div className='divider'></div>

										<Tooltip title={compact ? 'Maximizar' : 'Compactar'}>
											<Button
												size='small'
												onClick={() => setCompact(!compact)}
												disabled={script?.body?.length === 0}>
												{compact ? <FiMaximize /> : <FiMinimize />}
											</Button>
										</Tooltip>

										<Tooltip
											title={script?.body?.length !== 0 && !script?.isShared && 'Limpar modelo'}>
											<Button
												disabled={script?.body?.length === 0 || script?.isShared}
												type='danger'
												size='small'
												onClick={() => {
													Modal.confirm({
														title: 'Limpar modelo?',
														icon: 'exclamation-circle',
														content:
															'Todos os blocos e elementos serão removidos, deseja continuar?',
														okText: 'Limpar modelo',
														onOk: () => setScript({ ...script, body: [] }),
														okButtonProps: {
															icon: 'delete',
															type: 'danger',
														},
														cancelText: 'Cancelar',
														cancelButtonProps: {
															icon: 'close-circle',
														},
													});
												}}>
												<FiXSquare />
											</Button>
										</Tooltip>
									</OptionsContainer>

									<Divider style={{ margin: '8px 0' }} />

									<Script
										isAdmin={true}
										onSortEnd={onElementMove}
										loggedUser={user}
										showInactiveElements={false}
										tkVoiceTypes={tkVoiceTypes}
										onSortStart={() => setIsSorting(true)}
									/>
								</ScriptWrapper>
							</Grid>

							<Form.Item
								validateStatus={errors['name'] && 'error'}
								help={errors['name'] && 'Informe o nome do modelo'}>
								<Input
									size='large'
									placeholder='Nome do Modelo'
									onChange={(e) => setName(e.target.value)}
									style={{ width: '100%', marginTop: '16px' }}
								/>
							</Form.Item>

							<Form.Item
								style={{ width: '100%' }}
								validateStatus={errors['weekDay'] && 'error'}
								help={
									errors['weekDay'] && 'Informe o dia da semana à qual esse modelo corresponde'
								}>
								<Select
									size='large'
									placeholder='Dia da Semana'
									style={{ fontSize: 14, width: '100%' }}
									onChange={(value) => setWeekDay(value)}>
									<Select.Option value={0}>Domingo</Select.Option>
									<Select.Option value={1}>Segunda-Feira</Select.Option>
									<Select.Option value={2}>Terça-Feira</Select.Option>
									<Select.Option value={3}>Quarta-Feira</Select.Option>
									<Select.Option value={4}>Quinta-Feira</Select.Option>
									<Select.Option value={5}>Sexta-Feira</Select.Option>
									<Select.Option value={6}>Sábado</Select.Option>
								</Select>
							</Form.Item>

							<ButtonContainer>
								<Button
									type='primary'
									disabled={script.body.length === 0}
									onClick={handleCreateScript}
									loading={fallback?.updatingScript}>
									<FiSave /> Criar modelo
								</Button>
							</ButtonContainer>
						</Card>

						<ConfigScriptStrs
							visible={showConfigStrsModal}
							onConfirm={() => setShowConfigStrsModal(false)}
						/>
					</>
				)}
			</Container>
		</>
	);
};

export default EditScript;
