import React, { useCallback, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Tabs, Typography, Divider, Empty, Affix, Input } from 'antd';

import { Container, ElementList, Element, TabContent, RadioVignetteLabelContainer } from './styles';
import { useElement, useScript } from '../../hooks';

import {
	FiList,
	FiDollarSign,
	FiVoicemail,
	FiMusic,
	FiStopCircle,
	FiMic,
	FiActivity,
	FiGlobe,
	FiZap,
	FiUser,
	FiPlay,
	FiRadio,
	FiFastForward,
} from 'react-icons/fi';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { BsRobot } from 'react-icons/bs';
import { VignetteExecutionModesTranslation } from '../../enums/vignette-execution-modes';
import { VignetteSpeechModesTranslation } from '../../enums/vignette-speech-modes';

const ElementsTabs = ({
	isAdmin,
	allowGlobalNews,
	allowGlobalWpp,
	allowGlobalInterview,
	tkVoiceTypes,
	userId,
	isBlockTemplate,
	isTemplate,
}) => {
	const { script, elements, addElement, addMerchanElement } = useScript();
	const [activeKey, setActiveKey] = useState('Elementos Padrão');
	const { categorizedTypes, parseElementName, getElementColor, getElementIcon, radioVignettesMap } =
		useElement({ userId });

	const [search, setSearch] = useState({
		commom: '',
		programVignettes: '',
		staticRadioVignettes: '',
		scheduledRadioVignettes: '',
		customs: '',
		templateVignettes: '',
		merchans: '',
		cashTrack: '',
		localOffs: '',
		tkVox: '',
		signatures: '',
		tkPlay: '',
		localIa: '',
		globalIa: '',
		mainNews: '',
		localNews: '',
		globalNews: '',
		mainBlitz: '',
		localBlitz: '',
		globalBlitz: '',
		mainWpps: '',
		globalWpps: '',
		localWpps: '',
		mainInterviews: '',
		globalInterviews: '',
		localInterviews: '',
	});

	const handleChangeSearch = useCallback((key, value) => {
		setSearch((prev) => ({ ...prev, [key]: value }));
	}, []);

	const listElements = useCallback(
		(elements, searchKey, nameGetter) => {
			const searchValue = search[searchKey];
			return searchValue
				? elements.filter((e) => nameGetter(e)?.toLowerCase()?.includes(searchValue.toLowerCase()))
				: elements;
		},
		[search]
	);

	const checkTabVisibility = useCallback(
		(condition) => {
			if (isTemplate) {
				return true;
			}

			return condition;
		},
		[isTemplate]
	);

	const parseExecutionMode = useCallback(
		(mode) => VignetteExecutionModesTranslation[mode] ?? mode,
		[]
	);

	const parseSpeechMode = useCallback((mode) => VignetteSpeechModesTranslation[mode] ?? mode, []);

	return (
		<Affix offsetBottom={12}>
			<Container>
				<Typography.Title level={4}>{activeKey}</Typography.Title>

				<Divider style={{ margin: '8px 0' }} />

				<Tabs size='small' onChange={(key) => setActiveKey(key)}>
					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiList />
									<small>ELEMENTOS PADRÃO</small>
								</TabContent>
							}
							key='Elementos Padrão'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.commom}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('commom', e.target.value)}
								/>

								<ul>
									{listElements(
										isBlockTemplate
											? categorizedTypes.defaultElements.filter(
													(element) => !['NEW-BLOCK'].includes(element)
											  )
											: categorizedTypes.defaultElements,
										'commom',
										(type) => parseElementName({ type })
									).map((type, index) => (
										<Element
											onClick={() => {
												addElement({
													index: null,
													element: {
														type,
														options:
															type === 'OFF-MAIN'
																? { withSoundtrack: true }
																: type === 'TAG'
																? { color: '#FFF', name: '' }
																: null,
													},
												});
											}}
											color={getElementColor({ type })}
											key={index}>
											{getElementIcon({ type })}
											<span>{parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiActivity />
								<small>VHT DO PROGRAMA</small>
							</TabContent>
						}
						key='Vinhetas do Programa'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.programVignettes}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('programVignettes', e.target.value)}
							/>

							<ul>
								{listElements(categorizedTypes.programVignettes, 'programVignettes', (type) =>
									parseElementName({ type })
								).map((type, index) => (
									<Element
										onClick={() => {
											addElement({
												index: null,
												element: {
													type,
													options: null,
												},
											});
										}}
										color={getElementColor({ type })}
										key={index}>
										{getElementIcon({ type })}
										<span>{parseElementName({ type })}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiRadio />
								<small>VHT DA RÁDIO (CONTÍNUAS)</small>
							</TabContent>
						}
						key='Vinhetas da Rádio (Contínuas)'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.staticRadioVignettes}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('staticRadioVignettes', e.target.value)}
							/>
							<ul>
								{listElements(
									radioVignettesMap.staticTypes,
									'staticRadioVignettes',
									(v) => v.label
								).map((v, index) => (
									<Element
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: v.value,
													options: {
														executionMode: v.executionMode,
														speechMode: v.speechMode,
													},
												},
											});
										}}
										color={v.color}
										key={index}>
										<FiRadio />
										<RadioVignetteLabelContainer>
											<span>{v.label}</span>

											<div>
												<small>
													Categoria: <span>{parseSpeechMode(v.speechMode)}</span>
												</small>
												<small>
													Velocidade: <span>{parseExecutionMode(v.executionMode)}</span>
												</small>
											</div>
										</RadioVignetteLabelContainer>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiRadio />
								<small>VHT DA RÀDIO (CRONOGRAMADAS)</small>
							</TabContent>
						}
						key='Vinhetas da Rádio (Cronogramadas)'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.scheduledRadioVignettes}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('scheduledRadioVignettes', e.target.value)}
							/>
							<ul>
								{listElements(
									radioVignettesMap.scheduledTypes,
									'scheduledRadioVignettes',
									(v) => v.label
								).map((v, index) => (
									<Element
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'RADIO-VIGNETTE-CONFIG',
													options: {
														configId: v.value,
														executionMode: v.executionMode,
														speechMode: v.speechMode,
													},
												},
											});
										}}
										color={v.color}
										key={index}>
										<FiRadio />
										<RadioVignetteLabelContainer>
											<span>{v.label}</span>

											<div>
												<small>
													Categoria: <span>{parseSpeechMode(v.speechMode)}</span>
												</small>
												<small>
													Velocidade: <span>{parseExecutionMode(v.executionMode)}</span>
												</small>
											</div>
										</RadioVignetteLabelContainer>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiStopCircle />
									<small>CUSTOMIZADOS</small>
								</TabContent>
							}
							key='Elementos Customizados'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.customs}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('customs', e.target.value)}
								/>
								<ul>
									{elements.customs.length ? (
										listElements(elements.customs, 'customs', (e) => e.name).map(
											(element, index) => (
												<Element
													color={element.color}
													key={index}
													onClick={() => {
														addElement({
															index: null,
															element: {
																type: 'CUSTOM',
																options: {
																	color: element.color,
																	elementId: element?._id,
																	name: element?.name,
																},
															},
														});
													}}>
													{getElementIcon({ type: element.type })}
													<span>{element.name}</span>
												</Element>
											)
										)
									) : (
										<Empty description='Sem elementos customizados' />
									)}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(isAdmin) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiFastForward />
									<small>VINHETAS DE TEMPLATE</small>
								</TabContent>
							}
							key='Vinhetas de Template'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.templateVignettes}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('templateVignettes', e.target.value)}
								/>
								<ul>
									{elements.templateVignettes.length ? (
										listElements(
											elements.templateVignettes,
											'templateVignettes',
											(e) => e.name
										).map((element, index) => (
											<Element
												color={element.color}
												key={index}
												onClick={() => {
													addElement({
														index: null,
														element: {
															type: 'VIGNETTE-TEMPLATE',
															options: {
																color: element.color,
																elementId: element?._id,
																name: element?.name,
															},
														},
													});
												}}>
												{getElementIcon({ type: 'VIGNETTE-TEMPLATE' })}
												<span>{element.name}</span>
											</Element>
										))
									) : (
										<Empty description='Sem vinhetas cadastradas' />
									)}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiDollarSign />
								<small>PUBLICIDADES</small>
							</TabContent>
						}
						key='Publicidades'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.merchans}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('merchans', e.target.value)}
							/>
							{elements.merchans.length ? (
								<ul>
									{listElements(elements.merchans, 'merchans', (e) => e.name).map(
										(element, index) => (
											<Element
												color={
													element.color ? element.color : getElementColor({ type: element.type })
												}
												key={index}
												onClick={() => {
													addMerchanElement({
														blockIndex: null,
														element: {
															type: element.type,
															options: {
																name: element?.name,
																color: element.color,
																elementId: element?._id,
															},
														},
													});
												}}>
												{getElementIcon({ type: element.type })}
												<span>{element.name}</span>
											</Element>
										)
									)}
								</ul>
							) : (
								<Empty description='Sem publicidades' />
							)}
						</ElementList>
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiMusic />
								<small>MÚSICAS CASH</small>
							</TabContent>
						}
						key='Músicas-Cash'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.cashTrack}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('cashTrack', e.target.value)}
							/>
							{elements.cashTracks.length ? (
								<ul>
									{listElements(elements.cashTracks, 'cashTrack', (e) => e.name).map(
										(element, index) => (
											<Element
												color={getElementColor({ type: 'CASHTRACK' })}
												key={index}
												onClick={() => {
													addMerchanElement({
														blockIndex: null,
														element: {
															type: 'CASHTRACK',
															options: {
																elementId: element?._id,
																expirationDate: format(
																	parseISO(element?.expirationDate),
																	'dd/MM/yyyy'
																),
																name: element?.name,
															},
														},
													});
												}}>
												{getElementIcon({ type: element.type })}
												<span>{element.name}</span>
											</Element>
										)
									)}
								</ul>
							) : (
								<Empty description='Sem músicas-cash' />
							)}
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script?.isShared && !isAdmin) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiMic />
									<small>OFFS LOCAIS</small>
								</TabContent>
							}
							key='Offs Locais'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.localOffs}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('localOffs', e.target.value)}
								/>

								<ul>
									{listElements(categorizedTypes.localOffs, 'localOffs', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type,
														options: { withSoundtrack: true },
													},
												});
											}}>
											{getElementIcon({ type })}
											<span>{parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiVoicemail />
									<small>TKVOX</small>
								</TabContent>
							}
							key='TkVox'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.tkVox}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('tkVox', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.recordedOffs, 'tkVox', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type,
														options: { withSoundtrack: true },
													},
												});
											}}>
											{getElementIcon({ type })}
											<span>{parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiPlay />
									<small>TKPLAY</small>
								</TabContent>
							}
							key='TkPlay'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.tkPlay}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('tkPlay', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.tkPlay, 'tkPlay', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type,
														options: { withSoundtrack: true },
													},
												});
											}}>
											{getElementIcon({ type })}
											<span>{parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<BsRobot style={{ fontSize: 18 }} />
								<small>IA LOCAL</small>
							</TabContent>
						}
						key='IA Local'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.localIa}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('localIa', e.target.value)}
							/>
							<ul>
								{listElements(tkVoiceTypes.local, 'localIa', (type) =>
									parseElementName({ type })
								).map((type, index) => (
									<Element
										color={getElementColor({ type, isLocal: true })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type,
													options: { withSoundtrack: true, isLocal: true },
												},
											});
										}}>
										{getElementIcon({ type })}
										<span>IA LOCAL - {parseElementName({ type })}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={
							<TabContent>
								<BsRobot style={{ fontSize: 18 }} />
								<small>IA GLOBAL</small>
							</TabContent>
						}
						key='IA Global'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.globalIa}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('globalIa', e.target.value)}
							/>
							<ul>
								{listElements(tkVoiceTypes.caster, 'globalIa', (type) =>
									parseElementName({ type })
								).map((type, index) => (
									<Element
										color={getElementColor({ type, isLocal: false })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type,
													options: { withSoundtrack: true, isLocal: false },
												},
											});
										}}>
										{getElementIcon({ type })}
										<span>IA GLOBAL - {parseElementName({ type })}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiActivity />
									<small>ASSINATURAS</small>
								</TabContent>
							}
							key='Assinaturas'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.signatures}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('signatures', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.signatures, 'signatures', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type,
														options: null,
													},
												});
											}}>
											{getElementIcon({ type })}
											<span>{parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(!script.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiGlobe />
									<small>NOTÍCIAS REDE</small>
								</TabContent>
							}
							key='Notícias da rede'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.mainNews}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('mainNews', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.news, 'mainNews', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'NEWS-MAIN' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'NEWS-MAIN',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'NEWS-MAIN' })}
											<span>NOTÍCIA REDE - {parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiGlobe />
								<small>NOTÍCIAS LOCAIS</small>
							</TabContent>
						}
						key='Notícias locais'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.localNews}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('localNews', e.target.value)}
							/>
							<ul>
								{listElements(categorizedTypes.news, 'localNews', (type) =>
									parseElementName({ type })
								).map((type, index) => (
									<Element
										color={getElementColor({ type: 'NEWS-LOCAL' })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'NEWS-LOCAL',
													options: {
														withSoundtrack: true,
														category: type,
													},
												},
											});
										}}>
										{getElementIcon({ type: 'NEWS-LOCAL' })}
										<span>NOTÍCIA LOCAL - {parseElementName({ type })}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script.isShared && allowGlobalNews) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiGlobe />
									<small>NOTÍCIAS GLOBAIS</small>
								</TabContent>
							}
							key='Notícias Globais'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.globalNews}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('globalNews', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.news, 'globalNews', (type) =>
										parseElementName({ type })
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'NEWS-GLOBAL' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'NEWS-GLOBAL',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'NEWS-GLOBAL' })}
											<span>NOTÍCIA GLOBAL - {parseElementName({ type })}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiZap />
									<small>BLITZ REDE</small>
								</TabContent>
							}
							key='Blitz rede'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.mainBlitz}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('mainBlitz', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.blitz, 'mainBlitz', (type) =>
										type.split('-')[1].trim()
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'BLITZ-MAIN' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'BLITZ-MAIN',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'BLITZ-MAIN' })}
											<span>BLITZ REDE - {type.split('-')[1].trim()}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiZap />
								<small>BLITZ LOCAIS</small>
							</TabContent>
						}
						key='Blitz locais'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.localBlitz}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('localBlitz', e.target.value)}
							/>
							<ul>
								{listElements(categorizedTypes.blitz, 'localBlitz', (type) =>
									type.split('-')[1].trim()
								).map((type, index) => (
									<Element
										color={getElementColor({ type: 'BLITZ-LOCAL' })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'BLITZ-LOCAL',
													options: {
														withSoundtrack: true,
														category: type,
													},
												},
											});
										}}>
										{getElementIcon({ type: 'BLITZ-LOCAL' })}
										<span>BLITZ LOCAL - {type.split('-')[1].trim()}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiUser />
									<small>ENTREVISTAS REDE</small>
								</TabContent>
							}
							key='Entrevistas rede'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.mainInterviews}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('mainInterviews', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.interviews, 'mainInterviews', (type) =>
										type.split('-')[1].trim()
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'INTERVIEW-MAIN' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'INTERVIEW-MAIN',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'INTERVIEW-MAIN' })}
											<span>ENTREVISTA REDE - {type.split('-')[1].trim()}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<FiUser />
								<small>ENTREVISTAS LOCAIS</small>
							</TabContent>
						}
						key='Entrevistas locais'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.localInterviews}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('localInterviews', e.target.value)}
							/>
							<ul>
								{listElements(categorizedTypes.interviews, 'localInterviews', (type) =>
									type.split('-')[1].trim()
								).map((type, index) => (
									<Element
										color={getElementColor({ type: 'INTERVIEW-LOCAL' })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'INTERVIEW-LOCAL',
													options: {
														withSoundtrack: true,
														category: type,
													},
												},
											});
										}}>
										{getElementIcon({ type: 'INTERVIEW-LOCAL' })}
										<span>ENTREVISTA LOCAL - {type.split('-')[1].trim()}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script.isShared && allowGlobalInterview) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<FiUser />
									<small>ENTREVISTAS GLOBAIS</small>
								</TabContent>
							}
							key='Entrevistas Globais'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.globalInterviews}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('globalInterviews', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.interviews, 'globalInterviews', (type) =>
										type.split('-')[1].trim()
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'INTERVIEW-GLOBAL' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'INTERVIEW-GLOBAL',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'INTERVIEW-GLOBAL' })}
											<span>ENTREVISTA GLOBAL - {type.split('-')[1].trim()}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					{checkTabVisibility(!script?.isShared) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<AiOutlineWhatsApp />
									<small>WPP's REDE</small>
								</TabContent>
							}
							key='Wpps rede'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.mainWpps}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('mainWpps', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.wpps, 'mainWpps', (type) =>
										type.split('-')[1].trim()
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'WPP-MAIN' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'WPP-MAIN',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'WPP-MAIN' })}
											<span>WPP REDE - {type.split('-')[1].trim()}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}

					<Tabs.TabPane
						tab={
							<TabContent>
								<AiOutlineWhatsApp />
								<small>WPP's LOCAIS</small>
							</TabContent>
						}
						key='Wpps locais'>
						<ElementList>
							<Input.Search
								allowClear
								value={search.localWpps}
								size='large'
								placeholder='Pesquisar elementos'
								style={{ width: '100%' }}
								onChange={(e) => handleChangeSearch('localWpps', e.target.value)}
							/>
							<ul>
								{listElements(categorizedTypes.wpps, 'localWpps', (type) =>
									type.split('-')[1].trim()
								).map((type, index) => (
									<Element
										color={getElementColor({ type: 'WPP-LOCAL' })}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'WPP-LOCAL',
													options: {
														withSoundtrack: true,
														category: type,
													},
												},
											});
										}}>
										{getElementIcon({ type: 'WPP-LOCAL' })}
										<span>WPP LOCAL - {type.split('-')[1].trim()}</span>
									</Element>
								))}
							</ul>
						</ElementList>
					</Tabs.TabPane>

					{checkTabVisibility(!script.isShared && allowGlobalWpp) && (
						<Tabs.TabPane
							tab={
								<TabContent>
									<AiOutlineWhatsApp />
									<small>WPP's GLOBAIS</small>
								</TabContent>
							}
							key='Wpps Globais'>
							<ElementList>
								<Input.Search
									allowClear
									value={search.localWpps}
									size='large'
									placeholder='Pesquisar elementos'
									style={{ width: '100%' }}
									onChange={(e) => handleChangeSearch('localWpps', e.target.value)}
								/>
								<ul>
									{listElements(categorizedTypes.wpps, 'localWpps', (type) =>
										type.split('-')[1].trim()
									).map((type, index) => (
										<Element
											color={getElementColor({ type: 'WPP-GLOBAL' })}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'WPP-GLOBAL',
														options: {
															withSoundtrack: true,
															category: type,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'WPP-GLOBAL' })}
											<span>WPP GLOBAL - {type.split('-')[1].trim()}</span>
										</Element>
									))}
								</ul>
							</ElementList>
						</Tabs.TabPane>
					)}
				</Tabs>
			</Container>
		</Affix>
	);
};

export default ElementsTabs;
