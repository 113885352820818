import React, { useState, useEffect, useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Divider,
	Select,
	Modal,
	Typography,
	DatePicker,
	Tag,
	Breadcrumb,
	message,
	Checkbox,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import PlayCell from '../../../../components/PlayCell';
import Form from '../../../../components/Form';
import { Container, OptionContent, TableHeader } from './styles';

import WppsAPI from '../../../../services/sdks/wpps';
import ProgramsAPI from '../../../../services/sdks/programs';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { useElement, usePlayer, useDownload, useFilesValidator } from '../../../../hooks';
import {
	FiPlusCircle,
	FiShare2,
	FiTrash2,
	FiUploadCloud,
	FiXCircle,
	FiXOctagon,
} from 'react-icons/fi';
import FilesUploader from '../../../../components/FilesUploader';
import SelectOptWithBadge from '../../../../components/SelectOptWithBadge';

const Wpps = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();
	const uploaderRef = useRef();
	const { hasValidationError } = useFilesValidator();

	const {
		categorizedTypes: { wpps: categories },
		parseElementName,
	} = useElement();

	const [fallback, setFallback] = useState({ initialData: false });
	const [wpps, setWpps] = useState([]);
	const [isRandom, setIsRandom] = useState(false);
	const [isGlobal, setIsGlobal] = useState(false);
	const [selectedWpps, setSelectedWpps] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addWpp: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [newWpp, setNewWpp] = useState({
		category: undefined,
		programs: [],
		releaseDate: null,
		files: [],
	});

	const [filters, setFilters] = useState({
		program: undefined,
		category: undefined,
		releaseDate: null,
		isRandom: undefined,
	});

	const columns = [
		{
			title: 'Categoria',
			key: 'category',
			render: (wpp) => (
				<PlayCell
					meta={{
						primary: `WPP REDE - ${wpp.category.split('-')[1].trim()}`,
						secondary: wpp?.program?.name,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: wpp?.filename }),
							ref: wpp?._id,
							meta: {
								name: `WPP REDE - ${wpp.category.split('-')[1].trim()}`,
								artist: wpp?.program?.name,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === wpp?._id && player?.isPlaying}
				/>
			),
		},
		{
			title: 'Data de Lançamento',
			align: 'center',
			key: 'date',
			render: (wpp) => (wpp.isRandom ? '-' : wpp.releaseDate),
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (wpp) => <FileDuration src={resolveFileSrc({ fileName: wpp?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (wpp) => <FileExt src={resolveFileSrc({ fileName: wpp?.filename })} />,
		},
		{
			title: 'Wpp Randômico?',
			align: 'center',
			key: 'isRandom',
			render: (news) =>
				news?.isRandom ? (
					<Tag color='blue'>Randômico</Tag>
				) : (
					<Tag color='orange'>Não Randômico</Tag>
				),
		},
		user.allowGlobalWppSharing
			? {
					title: 'Wpp Global?',
					align: 'center',
					key: 'isGlobal',
					render: (news) =>
						news?.isGlobal ? <Tag color='green'>Global</Tag> : <Tag color='red'>Não Global</Tag>,
			  }
			: {},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (wpp) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: wpp?.filename }),
											ref: wpp?._id,
											meta: {
												artist: `WPP REDE - ${wpp.category.split('-')[1].trim()}`,
												name: wpp?.program?.name,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ wpp })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>
								{user.allowGlobalNewsSharing && (
									<Menu.Item
										key='share'
										onClick={() => handleUpdateIsGlobalFlag([wpp._id], !wpp.isGlobal)}>
										{wpp.isGlobal ? (
											<>
												<FiXOctagon /> Remover compartilhamento
											</>
										) : (
											<>
												<FiShare2 /> Compartilhar{' '}
											</>
										)}
									</Menu.Item>
								)}

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir off?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ wppId: wpp?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleUpdateIsGlobalFlag = useCallback(async (wppIds, isGlobal) => {
		try {
			setFallback((prev) => ({ ...prev, loadingActions: true }));
			message.loading('Atualizando...');

			await WppsAPI.updateGlobalFlag({ payload: { wppIds, isGlobal } });

			message.destroy();
			message.success(
				isGlobal
					? 'Os Wpps foram disponibilizadas com sucesso'
					: 'O compartilhamento dos wpps foi removido e as rádios não podem mais utilizá-los',
				6
			);
			setFallback((prev) => ({ ...prev, loadingActions: false }));
			setWpps((wpps) =>
				wpps.map((n) => {
					return wppIds.includes(n._id) ? { ...n, isGlobal } : n;
				})
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao atualizar os wpps');

			setFallback((prev) => ({ ...prev, loadingActions: false }));
		}
	}, []);

	const handleDownload = useCallback(
		async ({ wpp }) => {
			try {
				const name = `WPP REDE - ${wpp.category.split('-')[1].trim()}`;

				return download({ filename: wpp?.filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedWpps.length; index++) {
			const wpp = selectedWpps[index];
			const filePath = resolveFileSrc({ fileName: wpp?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `WPP REDE - ${wpp.category.split('-')[1].trim()}`;
			const ext = path.extname(wpp?.filename);
			const fName = `${wpp?.program?.name} - ${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });
		const selectedProgram = programs.find(({ _id }) => _id === filters?.program);

		let downloadName = "WPP'S REDE";

		selectedProgram && (downloadName = `${downloadName} - ${selectedProgram.name}`);
		filters?.releaseDate && (downloadName = `${downloadName} - ${filters.releaseDate}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [programs, selectedWpps, filters]);

	const handleDelete = useCallback(async ({ wppId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await WppsAPI.destroy({ wppId });

			setFallback((prev) => ({ ...prev, deleting: false }));
			setWpps((prev) => prev.filter(({ _id }) => _id !== wppId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar o wpp');

			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedWpps.length; index++) {
			const { _id: wppId } = selectedWpps[index];
			await handleDelete({ wppId });
		}

		setSelectedWpps([]);
	}, [selectedWpps, handleDelete]);

	const uploadWpps = useCallback(
		(file, programId) => {
			const payload = new FormData();

			payload.append('category', newWpp.category);
			payload.append('releaseDate', isRandom ? null : newWpp.releaseDate);
			payload.append('program', programId);
			payload.append('user', user._id);
			payload.append('isLocal', false);
			payload.append('isGlobal', isGlobal);
			payload.append('isRandom', isRandom);
			payload.append('file', file.data);

			return WppsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					file.upload.update(Math.floor((loaded * 100) / total));
				},
			});
		},
		[newWpp, user, isGlobal, isRandom]
	);

	const createProgramWpps = useCallback(
		async (programId) => {
			const programName = programs.find((p) => p._id === programId)?.name ?? '';
			message.loading(`Enviando wpps do programa ${programName}`, 4);
			await Promise.all(newWpp.files.map((file) => uploadWpps(file, programId)));
		},
		[newWpp.files, programs, uploadWpps]
	);

	const handleCreateWpps = useCallback(async () => {
		try {
			if (hasValidationError(newWpp.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const validationSchema = Yup.object().shape({
				category: Yup.string().required('Informe a categoria'),
				files: Yup.array().required('Selecione no mínimo 1 arquivo'),
				releaseDate: isRandom
					? Yup.mixed().nullable(true)
					: Yup.string().required('Informe a data de lançamento'),
			});
			
			await validationSchema.validate(newWpp);
			setFallback((prev) => ({ ...prev, uploadingWpp: true }));

			for (const program of newWpp.programs) {
				await createProgramWpps(program);
			}

			message.success('Wpps enviadas com sucesso', 3, () => {
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}
		}
	}, [hasValidationError, newWpp, createProgramWpps, isRandom]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchWpps = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingWpps: true }));

				let query = `isLocal=false&page=${pagination?.current - 1}&limit=${
					pagination?.pageSize
				}&user=${user?._id}&`;

				filters?.program && (query = `${query}program=${filters.program}&`);
				filters?.releaseDate && (query = `${query}releaseDate=${filters.releaseDate}&`);
				filters?.category && (query = `${query}category=${filters.category}&`);
				filters?.isRandom !== undefined && (query = `${query}isRandom=${filters.isRandom}&`);

				const {
					data: { wpps, total },
				} = await WppsAPI.index({ query });

				setPagination((prev) => ({ ...prev, total }));

				setWpps(wpps);
				setFallback((prev) => ({ ...prev, fetchingWpps: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as wpps');
			}
		};

		fetchWpps();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (user) {
			fetchInitialData();
		}
	}, [user]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Wpps' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'NARRAÇÕES' },
						{ breadcrumbName: 'WPPS REDE' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Wpps rede</Typography.Title>
					<Button
						type='primary'
						onClick={() => setVisibleModals({ ...visibleModals, addWpp: true })}>
						<FiPlusCircle /> Adicionar wpps
					</Button>
				</header>

				<Form.Container layout='1fr 1fr 1fr 1fr'>
					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							value={filters.program}
							placeholder='Programa'
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por categoria'>
						<Select
							showSearch
							value={filters.category}
							placeholder='Categoria'
							onChange={(category) => setFilters({ ...filters, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{categories.map((category) => (
								<Select.Option value={category} key={category}>
									WPP REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por data'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							value={filters?.releaseDate && moment(filters.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setFilters({ ...filters, releaseDate })}
						/>
					</Form.Item>

					<Form.Item label='Filtrar por randomicidade'>
						<Select
							value={filters.isRandom}
							placeholder='Randomicidade'
							onChange={(isRandom) => setFilters({ ...filters, isRandom })}>
							<Select.Option key='all' value={undefined}>
								<SelectOptWithBadge color='#CCC' label='Todos' />
							</Select.Option>

							<Select.Option key='is-random-true' value={true}>
								<SelectOptWithBadge color='blue' label='Randômicos' />
							</Select.Option>

							<Select.Option key='is-random-false' value={false}>
								<SelectOptWithBadge color='orange' label='Não Randômicos' />
							</Select.Option>
						</Select>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Dropdown.Button
								disabled={!selectedWpps.length || fallback.loadingActions}
								size='small'
								type='ghost'
								onClick={handleDownloadAsZIP}
								overlay={
									<Menu>
										<Menu.Item
											key='1'
											onClick={() => {
												handleUpdateIsGlobalFlag(
													selectedWpps.map((n) => n._id),
													true
												);
											}}>
											<FiShare2 />
											Compartilhar wpps selecionadas
										</Menu.Item>

										<Menu.Item
											key='2'
											onClick={() => {
												handleUpdateIsGlobalFlag(
													selectedWpps.map((n) => n._id),
													false
												);
											}}>
											<FiXOctagon />
											Remover compartilhamento
										</Menu.Item>

										<Menu.Divider />

										<Menu.Item
											key='3'
											onClick={() => {
												Modal.confirm({
													title: 'Deletar wpps selecionados?',
													type: 'danger',
													content:
														'Todos os wpps selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
													okText: 'Deletar',
													onOk: handleMultiDelete,
													okButtonProps: {
														icon: 'delete',
														type: 'danger',
													},
													cancelText: 'Cancelar',
													cancelButtonProps: {
														icon: 'close-circle',
													},
												});
											}}>
											<FiTrash2 />
											Deletar wpps selecionados
										</Menu.Item>
									</Menu>
								}>
								Baixar
							</Dropdown.Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback?.fetchingWpps}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={wpps}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedWpps(selectedRows),
					}}
				/>
			</Container>

			<Modal
				width={600}
				visible={visibleModals?.addWpp}
				onCancel={() => setVisibleModals({ ...visibleModals, addWpp: false })}
				onOk={handleCreateWpps}
				okButtonProps={{
					loading: fallback?.uploadingWpp,
					disabled: !newWpp?.files.length || !newWpp?.category || !newWpp?.programs.length,
				}}
				cancelButtonProps={{ disabled: fallback?.uploadingWpp }}
				okText={
					<>
						<FiUploadCloud /> Enviar wpp
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar wpp
					</>
				}>
				<Form.Container>
					<Form.Item
						style={{ marginBottom: 12 }}
						help='O WhatsApp será salvo no seu banco de vozes e inserido aleatoriamente no seu conteúdo.'>
						<Checkbox checked={isRandom} onChange={(e) => setIsRandom(e.target.checked)}>
							Wpp randômico
						</Checkbox>
					</Form.Item>

					<Form.Item label='Categoria'>
						<Select
							showSearch
							value={newWpp?.category}
							placeholder='Categoria'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(category) => setNewWpp({ ...newWpp, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option
									key={category}
									value={category}
									_search={parseElementName({ type: category })}>
									WPP REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Programas'>
						<Select
							showSearch
							mode='multiple'
							value={newWpp.programs}
							placeholder='Programas'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(programs) => setNewWpp({ ...newWpp, programs })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{!isRandom && (
						<Form.Item label='Data de lançamento'>
							<DatePicker
								placeholder='Data de lançamento'
								format='DD/MM/yyyy'
								style={{ width: '100%' }}
								value={newWpp?.releaseDate && moment(newWpp.releaseDate, 'DD/MM/yyyy')}
								onChange={(_, releaseDate) => setNewWpp({ ...newWpp, releaseDate })}
							/>
						</Form.Item>
					)}

					<FilesUploader
						multiple
						ref={uploaderRef}
						onChange={(files) => setNewWpp((prev) => ({ ...prev, files }))}
						validTypes={['.mp3', '.wav']}
					/>

					{user.allowGlobalWppSharing && (
						<Form.Item>
							<Checkbox checked={isGlobal} onChange={(e) => setIsGlobal(e.target.checked)}>
								Disponibilizar wpp's para as demais rádios
							</Checkbox>
						</Form.Item>
					)}
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Wpps);
